/* eslint-disable @next/next/no-img-element */

import React, { ComponentProps } from "react";

import { cn } from "@/lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { PopoverAnchor } from "@radix-ui/react-popover";
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "./command";
import { Skeleton } from "./skeleton";
import { CaretDown } from "@phosphor-icons/react";
import { Separator } from "./separator";
import { Popover, PopoverContent } from "./popover";
import { Input } from "./input";

export type TreeOption = {
  label: string;
  value: string;
  searchTerm?: string | null;
  children: TreeOption[];
};

export type TreeProps = {
  name?: string;
  placeholder?: string;
  empty?: React.ReactNode;
  options: TreeOption[];
  value: string;
  loading?: boolean;
  disabled?: boolean;
  action?: React.ReactNode;
  freeSolo?: boolean;
  disableGroups?: boolean;
  onValueChange: (value: string) => void;
  onEnter?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

type TreeItemProps = {
  option: TreeOption;
  disableGroups?: boolean;
  onSelect: (option: TreeOption) => void;
} & Omit<ComponentProps<typeof CommandItem>, "onSelect">;

function doesOptionMatch(option: TreeOption, searchTerm: string): boolean {
  if (
    (option.searchTerm || option.label)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  ) {
    return true;
  }

  if (option.children && Array.isArray(option.children)) {
    return option.children.some((child) => doesOptionMatch(child, searchTerm));
  }

  return false;
}

function findInTree(options: TreeOption[], value: string): TreeOption | null {
  if (!options || options.length === 0) return null;

  const [head, ...tail] = options;

  if (head.value === value) {
    return head;
  }

  if (head.children) {
    const foundInChildren = findInTree(head.children, value);
    if (foundInChildren) return foundInChildren;
  }

  return findInTree(tail, value);
}

function TreeItem(inProps: TreeItemProps) {
  const { option, onSelect, disableGroups } = inProps;

  const hasChildren = (option.children?.length || 0) > 0;

  return (
    <>
      <CommandItem
        {...inProps}
        value={option?.value}
        disabled={disableGroups && hasChildren}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        className={cn("flex w-full items-center gap-2 cursor-pointer")}
        onSelect={() => onSelect(option)}
      >
        {option.label}
      </CommandItem>
      {hasChildren && (
        <div className="ml-4">
          {option.children.map((child) => (
            <TreeItem
              key={child.value}
              option={child}
              onSelect={onSelect}
              disableGroups={disableGroups && option.children.length > 0}
            />
          ))}
        </div>
      )}
    </>
  );
}

const Tree = React.forwardRef<HTMLDivElement, TreeProps>(
  (
    {
      name,
      placeholder = "Selecione",
      disableGroups = true,
      empty,
      options,
      loading,
      disabled,
      value,
      freeSolo = false,
      onValueChange,
      onBlur,
      className,
      action,
      "aria-label": ariaLabel,
      onEnter,
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string | null>(
      findInTree(options, value)?.label || null
    );

    const [selected, setSelected] = React.useState<TreeOption>();
    const deferredInputValue = React.useDeferredValue(inputValue);

    const filtered = React.useMemo(() => {
      if (!deferredInputValue) return options;

      return options.filter((option) =>
        doesOptionMatch(option, deferredInputValue)
      );
    }, [options, deferredInputValue]);

    React.useEffect(() => {
      const selected = findInTree(options, value);

      if (inputValue === null && selected) {
        setInputValue(selected.label);
      }
    }, [inputValue, value, options]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input) {
          return;
        }

        // Keep the options displayed when the user is typing
        if (!isOpen && !freeSolo) {
          setOpen(true);
        }

        // This is not a default behaviour of the <input /> field
        if (event.key === "Enter" && input.value !== "") {
          onEnter?.();

          if (freeSolo) {
            input.blur();
            return;
          }

          const optionToSelect = options.find(
            (option) => option.label === input.value
          );
          if (optionToSelect) {
            onValueChange?.(optionToSelect.value);
          }
        }
        if (event.key === "Escape") {
          input.blur();
        }
      },
      [options, onValueChange, isOpen, freeSolo, onEnter]
    );

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLDivElement>) => {
        setOpen(false);
        if (!freeSolo) {
          setInputValue(selected?.label || "");
        }
        onBlur?.(event);
      },
      [selected, onBlur, freeSolo]
    );

    const handleSelectOption = React.useCallback(
      (selectedOption?: TreeOption) => {
        setSelected(selectedOption);
        setInputValue(selectedOption?.label || "");
        setOpen(false);
        onValueChange?.(selectedOption?.value || "");

        // This is a hack to prevent the input from being focused after the user selects an option
        // We can call this hack: "The next tick"
        setTimeout(() => {
          inputRef?.current?.blur();
        }, 0);
      },
      [onValueChange]
    );

    const handleFocus = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleValueChange = React.useCallback(
      (value: string) => {
        if (loading) return;

        setInputValue(value);
        if (freeSolo) {
          onValueChange(value);
        }
      },
      [loading, onValueChange, freeSolo]
    );

    const shouldPopoverContent = React.useMemo(() => {
      if (freeSolo && filtered?.length === 0) {
        return false;
      }

      return true;
    }, [freeSolo, filtered]);

    return (
      <Popover open={isOpen} modal>
        <CommandPrimitive
          ref={ref}
          onKeyDown={handleKeyDown}
          shouldFilter={false}
          className={className}
        >
          <PopoverAnchor className="relative">
            <Input
              role="combobox"
              ref={inputRef}
              aria-label={ariaLabel || name}
              value={inputValue || ""}
              onChange={(e) => handleValueChange(e.currentTarget.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={placeholder}
              disabled={disabled || loading}
              className="text-sm pr-8"
            />

            {!freeSolo && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <CaretDown size={16} />
              </div>
            )}
          </PopoverAnchor>
          {shouldPopoverContent && (
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              {loading && (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-8 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {options.length > 0 && !loading && (
                <CommandList className="overflow-auto p-[4px]">
                  <CommandGroup className="w-full overflow-auto relative">
                    {filtered?.map((option) => (
                      <TreeItem
                        key={option.value}
                        option={option}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        disableGroups={disableGroups}
                        onSelect={handleSelectOption}
                      />
                    ))}
                  </CommandGroup>
                </CommandList>
              )}

              {!loading && !freeSolo && (
                <CommandEmpty className="select-none rounded-sm px-2 py-3 flex flex-col items-center justify-center gap-3">
                  <img src="/images/box.png" width={68} height={81} alt="Box" />
                  <span className="text-center text-sm text-muted-foreground">
                    {empty}
                  </span>
                </CommandEmpty>
              )}
              {action && (
                <>
                  <Separator className="mt-1" />
                  <div className="p-1">{action}</div>
                </>
              )}
            </PopoverContent>
          )}
        </CommandPrimitive>
      </Popover>
    );
  }
);
Tree.displayName = "Tree";

export { Tree };
