import { ApiAdapter, createServicesProvider, SocketAdapter } from "@/adapters";

import { AuthService } from "./auth.service";
import { UserService } from "./user.service";
import { CompanyService } from "./company.service";
import { ContactService } from "./contact.service";
import { TutorialService } from "./tutorial.service";
import { TaxService } from "./tax.service";
import { ServicesService } from "./services.service";
import { ProductsService } from "./products.service";
import { InvoiceService } from "./invoice.service";
import { AddressService } from "./address.service";
import { CategoryService } from "./category.service";
import { CertificateService } from "./certificate.service";
import { InstitutionsService } from "./institutions.service";
import { AccountsService } from "./accounts.service";
import { BillService } from "./bill.service";
import { ReceivableService } from "./receivable.service";
import { AttachmentService } from "./attachment.service";
import { EntriesService } from "./entries.service";
import { CheckoutService } from "./checkout.service";
import { SubscriptionService } from "./subscription.service";
import { LegalEntitiesService } from "./legal-entities.service";
import { NaturalEntitiesService } from "./natural-entities.service";
import { AnnualBillingStatementsService } from "./annual-billing-statements.service";
import { AssociatesService } from "./associates.service";
import { DependentsService } from "./dependents.service";

export type ServiceContextData = {
  associates: AssociatesService;
  annualBillingStatements: AnnualBillingStatementsService;
  address: AddressService;
  auth: AuthService;
  user: UserService;
  contact: ContactService;
  company: CompanyService;
  category: CategoryService;
  certificate: CertificateService;
  tutorial: TutorialService;
  tax: TaxService;
  product: ProductsService;
  service: ServicesService;
  invoice: InvoiceService;
  institutions: InstitutionsService;
  account: AccountsService;
  bill: BillService;
  receivable: ReceivableService;
  attachment: AttachmentService;
  entries: EntriesService;
  checkout: CheckoutService;
  subscription: SubscriptionService;
  legalEntities: LegalEntitiesService;
  naturalEntities: NaturalEntitiesService;
  dependents: DependentsService;
};

const apiAdapter = new ApiAdapter(import.meta.env.VITE_CONNECT_API_URL!);
const socketAdapter = new SocketAdapter(import.meta.env.VITE_CONNECT_API_URL!);

function configService(): ServiceContextData {
  return {
    associates: new AssociatesService(apiAdapter),
    annualBillingStatements: new AnnualBillingStatementsService(
      apiAdapter,
      socketAdapter
    ),
    attachment: new AttachmentService(apiAdapter),
    address: new AddressService(apiAdapter),
    dependents: new DependentsService(apiAdapter),
    tax: new TaxService(apiAdapter),
    auth: new AuthService(apiAdapter),
    user: new UserService(apiAdapter),
    contact: new ContactService(apiAdapter),
    category: new CategoryService(apiAdapter),
    company: new CompanyService(apiAdapter),
    certificate: new CertificateService(apiAdapter),
    tutorial: new TutorialService(apiAdapter),
    product: new ProductsService(apiAdapter),
    service: new ServicesService(apiAdapter),
    invoice: new InvoiceService(apiAdapter),
    institutions: new InstitutionsService(apiAdapter),
    account: new AccountsService(apiAdapter),
    bill: new BillService(apiAdapter),
    receivable: new ReceivableService(apiAdapter),
    entries: new EntriesService(apiAdapter),
    checkout: new CheckoutService(apiAdapter),
    naturalEntities: new NaturalEntitiesService(apiAdapter),
    subscription: new SubscriptionService(apiAdapter),
    legalEntities: new LegalEntitiesService(apiAdapter),
  };
}

export const { useServices: useService, ServicesProvider } =
  createServicesProvider(configService());
