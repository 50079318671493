import { Address } from "../address";

export type LegalEntity = {
  id: string;
  document_number: string;
  trade_name?: string;
  name: string;
  email?: string;
  address?: Address;
  phone_number?: string;
  verified?: boolean;
  situation: LegalEntitySituation;
};

export enum LegalEntitySituation {
  ATIVA = "Ativa",
  INAPTA = "Inapta",
  BAIXADA = "Baixada",
}
