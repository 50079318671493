import { forwardRef, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";

import { useService } from "@/services";
import { LegalEntity, LegalEntitySituation } from "@/@types/entities";
import { formatCompanyDocumentNumber } from "@/modules/formatters";
import { Autocomplete, AutocompleteProps, unMask } from "@/components/ui";
import { useLegalEntities } from "../hooks";

type Props = {
  naturalDocumentNumber: string;
  onLegalEntityChange?: (legalEntity?: LegalEntity) => void;
} & Omit<AutocompleteProps, "options">;

export const OwnerCompaniesContainer = forwardRef<HTMLDivElement, Props>(
  function OwnerCompaniesContainer(inProps, ref) {
    const { naturalDocumentNumber, onValueChange, onLegalEntityChange } =
      inProps;

    const { legalEntities: legalEntitiesService } = useService();
    const { legalEntities, isLoading } = useLegalEntities(
      naturalDocumentNumber
    );

    const { mutateAsync: getLegalEntity, isPending } = useMutation({
      mutationFn: (documentNumber: string) =>
        legalEntitiesService.getRegistrationData(documentNumber),
    });

    const companies = useMemo(() => {
      if (!legalEntities) {
        return [];
      }

      return legalEntities
        .filter(
          (legalEntity) => legalEntity.situation === LegalEntitySituation.ATIVA
        )
        .map((legalEntity) => ({
          label: `${
            legalEntity.trade_name || legalEntity.name
          } (${formatCompanyDocumentNumber(legalEntity.document_number)})`,
          value: legalEntity.document_number,
        }));
    }, [legalEntities]);

    async function handleChange(value: string) {
      const unMasked = unMask(value);

      onValueChange?.(unMasked);

      if (!legalEntities && unMasked.length === 14) {
        const legalEntity = await getLegalEntity(unMasked);

        return onLegalEntityChange?.({
          id: legalEntity.id,
          name: legalEntity.name,
          document_number: legalEntity.document_number,
          trade_name: legalEntity.trade_name || "",
          situation: LegalEntitySituation.ATIVA,
          verified: false,
        });
      }

      const legalEntity = legalEntities?.find((legalEntity) => {
        return legalEntity.document_number === value;
      });

      if (legalEntity) {
        onLegalEntityChange?.({
          ...legalEntity,
          verified: true,
        });
      }
    }

    return (
      <Autocomplete
        ref={ref}
        freeSolo
        shouldUseValueOnLabel
        mask={["99.999.999/9999-99"]}
        options={companies}
        loading={isLoading || isPending}
        {...inProps}
        onValueChange={handleChange}
      />
    );
  }
);
