import { Adapter, SocketAdapter } from "@/@types";
import {
  AnnualBillingStatement,
  CreateAnnualBillingStatement,
} from "@/@types/annual-billing-statements";
import { QueryClient } from "@tanstack/react-query";

export class AnnualBillingStatementsService {
  constructor(
    private readonly http: Adapter,
    private readonly socket: SocketAdapter
  ) {}

  async subscribeEvent(queryClient: QueryClient) {
    this.socket.on("annual-billing-statements", () => {
      queryClient.invalidateQueries({
        queryKey: ["annual_billing_statements"],
      });
    });
  }

  async unsubscribeEvent() {
    this.socket.off("annual-billing-statements", () => {});
  }

  async getAnnualBillingStatements(
    companyId: string
  ): Promise<AnnualBillingStatement[]> {
    return this.http
      .get(`/v1/companies/${companyId}/annual-billing-statements`)
      .then((response) => response.data);
  }

  async createAnnualBillingStatement(
    companyId: string,
    payload: CreateAnnualBillingStatement
  ): Promise<AnnualBillingStatement> {
    return this.http
      .post(`/v1/companies/${companyId}/annual-billing-statements`, payload)
      .then((response) => response.data);
  }
}
