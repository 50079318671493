import { Adapter } from "@/@types";
import { LegalEntity } from "@/@types/entities";

export class LegalEntitiesService {
  constructor(private readonly http: Adapter) {}

  async getRegistrationData(
    companyDocumentNumber: string
  ): Promise<LegalEntity> {
    return this.http
      .get(`/v1/legal_entities/${companyDocumentNumber}/registration_data`)
      .then((response) => response.data);
  }
}
