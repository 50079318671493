import { useService } from "@/services";
import { useQuery } from "@tanstack/react-query";

export function useLegalEntities(naturalDocumentNumber: string) {
  const { naturalEntities: naturalEntitiesService } = useService();

  const { data, ...rest } = useQuery({
    queryKey: ["legal_entities", naturalDocumentNumber],
    queryFn: () =>
      naturalEntitiesService.getLegalEntities(naturalDocumentNumber),
  });

  return {
    legalEntities: data,
    ...rest,
  };
}
