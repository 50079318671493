import { Adapter } from "@/@types";
import {
  CreatePaymentPayload,
  CreatePreApprovalPayload,
} from "@/@types/checkout";
import { Billing } from "@/@types/checkout/billing";

export class CheckoutService {
  constructor(private readonly http: Adapter) {}

  async createPayment(payload: CreatePaymentPayload): Promise<{
    line_code: string;
    qr_code_base64: string;
    billing_id: string;
  }> {
    return this.http
      .post(`/v1/checkout/payments`, payload)
      .then((response) => response.data);
  }

  async createPreApproval(
    payload: CreatePreApprovalPayload
  ): Promise<{ billing_id: string; status: string }> {
    return this.http
      .post(`/v1/checkout/pre-approvals`, payload)
      .then((response) => response.data);
  }

  async getBillings(): Promise<Billing[]> {
    return this.http.get(`/v1/billings`).then((response) => response.data);
  }

  async getBilling(billingId: string): Promise<{
    status: "PAID" | "PENDING";
  }> {
    return this.http
      .get(`/v1/billings/${billingId}`)
      .then((response) => response.data);
  }
}
