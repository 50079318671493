import { Adapter } from "@/@types";
import {
  Company,
  CompanyAccessInvitation,
  CreateCompanyPayload,
} from "@/@types/company";

export class CompanyService {
  constructor(private readonly http: Adapter) {}

  async verifyDocumentNumber(companyDocumentNumber: string): Promise<boolean> {
    return this.http
      .get(`/v1/companies/${companyDocumentNumber}/valid`)
      .then((response) => response.data.exists);
  }

  async createCompany(payload: CreateCompanyPayload) {
    return this.http
      .post(`/v1/companies`, payload)
      .then((response) => response.data);
  }

  async getCompanyAccessInvitations(): Promise<CompanyAccessInvitation[]> {
    return this.http.get(`/v1/invitations`).then((response) => response.data);
  }

  async createCompanyAccessInvitation(
    documentNumber: string
  ): Promise<CompanyAccessInvitation> {
    return this.http
      .post(`/v1/companies/${documentNumber}/invitations`)
      .then((response) => response.data);
  }

  async acceptCompanyAccessInvitation(
    invitationId: string
  ): Promise<CompanyAccessInvitation> {
    return this.http
      .patch(`/v1/invitations/${invitationId}/accept`)
      .then((response) => response.data);
  }

  async rejectCompanyAccessInvitation(
    invitationId: string
  ): Promise<CompanyAccessInvitation> {
    return this.http
      .patch(`/v1/invitations/${invitationId}/reject`)
      .then((response) => response.data);
  }

  async getCompanyProfile(companyId: string): Promise<Company> {
    return this.http
      .get(`/v1/companies/${companyId}/profile`)
      .then((response) => response.data);
  }

  async updateCompany(
    companyId: string,
    payload: Partial<Company>
  ): Promise<Company> {
    return this.http
      .patch(`/v1/companies/${companyId}`, payload)
      .then((response) => response.data);
  }
}
