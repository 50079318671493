import { Adapter } from "@/@types";

import {
  Dependent,
  CreateDependent,
  UpdateDependent,
} from "@/@types/dependents";

export class DependentsService {
  constructor(private readonly http: Adapter) {}

  async getDependent(
    companyId: string,
    dependentId: string
  ): Promise<Dependent> {
    return this.http
      .get(`/v1/companies/${companyId}/dependents/${dependentId}`)
      .then((response) => response.data);
  }

  async getDependents(
    companyId: string,
    associateId: string
  ): Promise<Dependent[]> {
    return this.http
      .get(`/v1/companies/${companyId}/dependents`, {
        params: {
          associate_id: associateId,
        },
      })
      .then((response) => response.data);
  }

  async createDependent(
    companyId: string,
    payload: CreateDependent
  ): Promise<Dependent> {
    return this.http
      .post(`/v1/companies/${companyId}/dependents`, payload)
      .then((response) => response.data);
  }

  async updateDependent(
    companyId: string,
    { dependent_id, ...rest }: UpdateDependent
  ): Promise<Dependent> {
    return this.http
      .patch(`/v1/companies/${companyId}/dependents/${dependent_id}`, rest)
      .then((response) => response.data);
  }

  async deleteDependent(
    companyId: string,
    dependentId: string
  ): Promise<Dependent> {
    return this.http
      .delete(`/v1/companies/${companyId}/dependents/${dependentId}`)
      .then((response) => response.data);
  }
}
