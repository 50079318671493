import { Adapter } from "@/@types";
import { LegalEntity, NaturalEntity } from "@/@types/entities";

export class NaturalEntitiesService {
  constructor(private readonly http: Adapter) {}

  async getRegistrationData(
    companyDocumentNumber: string
  ): Promise<NaturalEntity> {
    return this.http
      .get(`/v1/natural_entities/${companyDocumentNumber}/registration_data`)
      .then((response) => response.data);
  }

  async getLegalEntities(
    companyDocumentNumber: string
  ): Promise<LegalEntity[]> {
    return this.http
      .get(`/v1/natural_entities/${companyDocumentNumber}/legal_entities`)
      .then((response) => response.data);
  }
}
