import { Adapter } from "@/@types";
import {
  Category,
  CategoryBalance,
  CreateCategoryBudget,
} from "@/@types/category";

export class CategoryService {
  constructor(private readonly http: Adapter) {}

  async initialSetupCategories(companyId: string) {
    return this.http
      .post(`/v1/companies/${companyId}/categories/initial_setup`)
      .then((response) => response.data);
  }

  async createCategory({
    company_id,
    ...rest
  }: Pick<
    Category,
    "name" | "parent_category" | "kind" | "company_id"
  >): Promise<Category> {
    return this.http
      .post(`/v1/companies/${company_id}/categories`, rest)
      .then((response) => response.data);
  }

  async upsertCategoryBudget({
    company_id,
    category_id,
    ...rest
  }: CreateCategoryBudget): Promise<Category> {
    return this.http
      .patch(
        `/v1/companies/${company_id}/categories_budget/${category_id}`,
        rest
      )
      .then((response) => response.data);
  }

  async updateCategory({
    company_id,
    category_id,
    ...rest
  }: { category_id: string } & Pick<
    Category,
    "name" | "company_id"
  >): Promise<Category> {
    return this.http.patch(
      `/v1/companies/${company_id}/categories/${category_id}`,
      rest
    );
  }

  async inactiveCategory(
    companyId: string,
    categoryId: string
  ): Promise<Category> {
    return this.http.patch(
      `/v1/companies/${companyId}/categories/${categoryId}/inactive`
    );
  }

  async activeCategory(
    companyId: string,
    categoryId: string
  ): Promise<Category> {
    return this.http.patch(
      `/v1/companies/${companyId}/categories/${categoryId}/active`
    );
  }

  async getCategory(companyId: string, categoryId: string): Promise<Category> {
    return this.http
      .get(`/v1/companies/${companyId}/categories/${categoryId}`)
      .then((response) => response.data);
  }

  async getCategories(companyId: string): Promise<Category[]> {
    return this.http
      .get(`/v1/companies/${companyId}/categories`)
      .then((response) => response.data);
  }

  async getCategoriesBudget(
    companyId: string,
    date?: string
  ): Promise<Category[]> {
    return this.http
      .get(`/v1/companies/${companyId}/categories_budget`, {
        params: {
          date,
        },
      })
      .then((response) => response.data);
  }

  async getCategoriesBalance(
    companyId: string,
    date?: string
  ): Promise<CategoryBalance[]> {
    return this.http
      .get(`/v1/companies/${companyId}/categories/balance`, {
        params: {
          date,
        },
      })
      .then((response) => response.data);
  }

  async downloadCategories(companyId: string, date?: string): Promise<any> {
    return this.http
      .get(`/v1/companies/${companyId}/categories_budget/download`, {
        responseType: "blob",
        params: {
          date,
        },
      })
      .then((response) => response.data);
  }
}
