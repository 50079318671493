import { useEffect } from "react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";
import { toast, ToastAction } from "@/components/ui";

async function getVersion(): Promise<string> {
  const response = await axios.get("/version.json", {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return response.data.version;
}

export function VersionCheckerContainer() {
  const currentVersion = window.RUNTIME_VERSION;

  const { data, isSuccess } = useQuery({
    queryKey: ["version"],
    queryFn: () => getVersion(),
    refetchInterval: 60000,
    enabled: import.meta.env.MODE === "production",
  });

  useEffect(() => {
    if (isSuccess && !!currentVersion && data !== currentVersion) {
      toast({
        duration: Infinity,
        variant: "warning",
        title: "Atualização disponível!",
        description:
          "Uma nova versão da Setfin está disponível, atualize agora!",
        action: (
          <ToastAction
            altText="Atualizar"
            onClick={() => window.location.reload()}
          >
            Atualizar
          </ToastAction>
        ),
      });
    } else if (!currentVersion) {
      window.RUNTIME_VERSION = data!;
    }
  }, [isSuccess, data, currentVersion]);

  return null;
}
